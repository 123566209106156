import { createApp } from 'vue';
import UniqueIdEnqueteApp from '../enquete/components/UniqueIdEnqueteApp';

document.addEventListener('DOMContentLoaded', () => {
  const uniqueIdEnqueteApp = document.getElementById('uniqueid-enquete-app');
  if (uniqueIdEnqueteApp?.getAttribute('data-hae-survey') === 'true') {
    const uniqueId = uniqueIdEnqueteApp.getAttribute('data-unique-id');
    const haeSurveyUrl = uniqueIdEnqueteApp.getAttribute('data-hae-survey-url');
    createApp(UniqueIdEnqueteApp, {
      linkUrl: `${haeSurveyUrl}?p_id=${uniqueId}`,
      linkText: 'さっそく答える（約1〜2分）'
    }).mount('#uniqueid-enquete-app');
  }
});
